<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.childrenList">
          <el-submenu :key="item.id" :index="item.url">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.childrenList">
              <el-submenu
                v-if="subItem.childrenList"
                :key="subItem.id"
                :index="subItem.url"
              >
                <template slot="title">
                  <i :class="subItem.icon"></i>
                  <span slot="title">{{ subItem.title }}</span>
                </template>
                <el-menu-item
                  v-for="threeItem in subItem.childrenList"
                  :key="threeItem.id"
                  :index="threeItem.url"
                >
                  <!-- {{ threeItem.title }} -->
                  <template slot="title">
                    <i :class="threeItem.icon"></i>
                    <span slot="title">{{ threeItem.title }}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item v-else :key="subItem.id" :index="subItem.url">
                <i :class="subItem.icon"></i>
                {{ subItem.title }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :key="item.id" :index="item.url">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '../common/bus';
import { getMenuListByUserid } from '@/api/admin/user';

export default {
  data() {
    return {
      collapse: false,
      items: [
        {
          icon: 'el-icon-lx-home',
          url: '/dashboard',
          title: '系统首页'
        }
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path;
    }
  },
  created() {
    this.loadMenuTree();
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on('collapse', msg => {
      this.collapse = msg;
    });
  },
  methods: {
    //加载左侧菜单树
    loadMenuTree() {
      const userid = sessionStorage.getItem('userId');
      getMenuListByUserid({ id: userid }).then(resp => {
        const menuData = resp.data;
        menuData.forEach(item => {
          this.items.push(item);
        });
        let menuList = this.getAllMenuList(this.items);
        sessionStorage.setItem('menuList', JSON.stringify(menuList));
      });
    },
    getAllMenuList(menuList = [], arrs = []) {
      for (var i = 0; i < menuList.length; i++) {
        if (menuList[i].childrenList && menuList[i].childrenList.length > 0) {
          this.getAllMenuList(menuList[i].childrenList, arrs);
        }
        arrs.push(menuList[i]);
      }
      return arrs;
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 300px;
}

.sidebar > ul {
  height: 100%;
}

// 大字号样式
.el-menu {
  i {
    margin-right: 1rem;
    font-size: 24px;
  }

  .el-menu-item {
    padding: 18px 0;
    height: auto;
    line-height: 1;
    font-size: 20px;
  }

  .el-submenu {
    .el-menu {
      .el-menu-item {
        padding: 18px 0;
        height: auto;
        line-height: 1;
        font-size: 20px;
      }
    }
  }
}
</style>

<style>
.el-submenu__title {
  padding: 18px 0;
  height: auto;
  line-height: 1;
  font-size: 20px;
}
</style>
